export const SUBJECT_PRODUCT_SW = 'tips.productDetails.productSoftware'
export const SUBJECT_IMPLEMENTATION = 'tips.productDetails.implementation'
export const SUBJECT_DEVELOPMENT = 'tips.productDetails.development'
export const SUBJECT_SUPPORT = 'tips.productDetails.supportLevel'

export const quotationRequestSubjects = [
  SUBJECT_PRODUCT_SW,
  SUBJECT_IMPLEMENTATION,
  SUBJECT_DEVELOPMENT,
  SUBJECT_SUPPORT
]
