<template>
  <v-row>
    <v-col
      cols="12"
      sm="7"
    >
      <h3
        v-t="'productDetails.services'"
        class="mb-3"
      />
      <!-- FIXME: key is ussing dark pattern. Service entity should get own identifier assigned by server -->
      <div
        v-for="(service, index) in contract.product.services"
        :key="`${service}-${index}`"
      >
        {{ service }}
      </div>
      <div
        v-if="!contract.product.services.length"
        v-t="'productDetails.noServiceHasBeenOrderedYet'"
      />
    </v-col>
    <v-col
      class="text-center"
      cols="12"
      sm="5"
    >
      <material-request-quotation-modal-window
        :has-button-slot="true"
        :default-subject="SUBJECT_IMPLEMENTATION"
      >
        <template #activator-button="{on}">
          <v-btn
            rounded
            v-t="'productDetails.newServiceOrder'"
            depressed
            outlined
            color="primary"
            data-cy="service-order-button"
            v-on="on"
          />
        </template>
      </material-request-quotation-modal-window>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex'
import { SUBJECT_IMPLEMENTATION } from '@/constants/quotationRequestSubjects'

export default {
  data () {
    return {
      SUBJECT_IMPLEMENTATION
    }
  },
  computed: {
    ...mapGetters('client', ['client']),
    contract () {
      const routeParams = this.$route.params
      return this.$store.getters['contract/contract']({
        clientId: routeParams.clientId,
        contractId: routeParams.contractId
      })
    }
  }
}
</script>
